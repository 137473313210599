import { Component, HostListener, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { trigger, style, animate, query, stagger, transition, group } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';

import { SectionService } from '../../services/section.service';

@Component({
  selector: 'app-header',
  animations: [
    trigger(
      'slideIn',[
        transition(':enter', [
          group([
            style({height: '0'}),
            query('.mobile-options-item', [
              style({opacity:0})
            ]),
            animate('500ms ease-out', style({height: 'calc(100vh - 52px)'})),
            query('.mobile-options-item', [
              stagger('100ms', [ animate('100ms', style({opacity:1})) ])
            ])
          ])
        ]),
        transition(':leave', [
          group([
            style({height: 'calc(100vh - 52px)'}),
            query('*', [
              style({opacity:1})
            ]),
            query('.mobile-options-item', [
              stagger('-25ms', [ animate('50ms', style({opacity:0})) ])
            ]),
            animate('500ms ease-out', style({height: '0'}))
          ])
        ])
      ]
    ),
    trigger(
      'fade',[
        transition(':enter', [
          query('*', [
            style({opacity:0}),
            animate('1000ms', style({opacity:1}))
          ])
        ]),
        transition(':leave', [
          query('*', [
            style({opacity:1}),
            animate('1000ms', style({opacity:0}))
          ])
        ])
      ]
    )
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  contentLoaded: boolean = false;

  showMobileMenu: boolean = false;

  currentRoute: {
    'url': any
  }
  
  dark: boolean = false;

  mode;

  navigation;

  touch;

  constructor(private router: Router, private sectionService: SectionService, private cd: ChangeDetectorRef) {
    this.currentRoute = router;
  }

  goToSection(slug, event) {
    if(this.currentRoute.url != '/') {
      this.router.navigate(['/']);
      setTimeout(() => {
        this.sectionService.goToSection(slug, event);
      }, 1500);
    } else {
      this.sectionService.goToSection(slug, event);
    }
  }

  goTo(href, target='_blank') {
    window.open(href, target);
  }

  toggleMobileOptions() {
  	this.showMobileMenu = !this.showMobileMenu;
  }

  getHeaderHeight() {
    return document.getElementsByClassName('header')[0].clientHeight;
  }

  getBannerHeight() {
    if(document.getElementsByClassName('banner')) {
      return document.getElementsByClassName('banner')[0].clientHeight;
    }
  }

  ngOnInit() {
    this.sectionService.changes.subscribe((data) => {
      if(!this.navigation) {
        this.navigation = this.sectionService.navigation.condensed;
      }

      this.mode = this.sectionService.mode;

      this.touch = this.sectionService.touchDevice;
    });

    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this.contentLoaded = true;

    this.cd.detectChanges();
  }
}
