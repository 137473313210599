import { Component, OnInit, Input } from '@angular/core';

import { SectionService } from '../../services/section.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {

  @Input() listing: {
    'title':null,
    'overview':null,
    'description':null,
    'specifics':null,
    'responsibilities':null,
    'experience':null,
    'skills':null,
    'softSkills':null,
    'bonusSkills':null,
    'notes':null,
    'show':boolean
  };

  mode;

  constructor(private sectionService: SectionService, private modalService: ModalService) { }

  setModal(value, options, e){
    e.preventDefault();
    this.modalService.setModal(value, options);
  }

  ngOnInit() {
    this.sectionService.changes.subscribe((data) => {
      this.mode = this.sectionService.mode;
    });
  }

}
