import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { CareersComponent } from './pages/careers/careers.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Rivet CX',
      description:'Rivet CX provides a human-centered design approach to solutions that optimize user experiences for organizations worldwide',
      ogUrl: 'https://rivetcx.com'
    }
  },
  // {
  //   path: 'careers',
  //   component: CareersComponent,
  //   data: {
  //     title: 'Careers | Rivet CX',
  //     description:'Rivet CX is always looking for great talent to join us in creating amazing, human-centered designs. Come work with us!',
  //     ogUrl: 'https://rivetcx.com/careers'
  //   }
  // },
  { path: 'home', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  declarations: [],
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'}) ]
})

export class AppRoutingModule {}
