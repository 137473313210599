import { Component, OnInit, HostListener } from '@angular/core';
import { trigger, style, animate, query, stagger, transition } from '@angular/animations';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { SeoService } from './services/seo.service';
import { SectionService } from './services/section.service';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  animations: [
    trigger(
      'fade',[
        transition(':enter', [
          query('*', [
            style({opacity:0}),
            animate('500ms', style({opacity:1}))
          ])
        ]),
        transition(':leave', [
          query('*', [
            style({opacity:1}),
            animate('500ms', style({opacity:0}))
          ])
        ])
      ]
    )
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  contentLoaded = false;
  anim: any;
  animCompleted: boolean = false;

  config = {
    'container': document.getElementById('lottie-loading-anim'),
    'name':'Loading',
    'renderer': 'svg',
    'autoplay': false,
    'loop': false,
    'path': './assets/animations/RivetLogo.json'
  }

  lastDir = "down";

  isScrolling = false;

  constructor(public seo: SeoService, private sectionService: SectionService, public router: Router, public activatedRoute: ActivatedRoute){ }

  // @HostListener('wheel', ['$event'])
  // onWindowScroll($event: MouseWheelEvent) {
  //   if(!this.isScrolling && this.router.url == "/" && !this.sectionService.touchDevice && !this.sectionService.shortDevice){
  //     this.isScrolling = true;
  //     $event.preventDefault();

  //     if ($event.deltaY < 0) {
  //       this.lastDir = "up";
  //     }
  //     else if ($event.deltaY > 0) {
  //       this.lastDir = "down";
  //     }

  //     if(this.sectionService.currentSection == "home" || this.sectionService.currentSection == "project-costs" || this.sectionService.currentSection == "mitigates-risk" || this.sectionService.currentSection == "fosters-growth" || this.sectionService.currentSection == "support") {
  //       document.body.style.overflow = "hidden";
  //     } else if(this.sectionService.currentSection == "approach" && this.lastDir == "up") {
  //       document.body.style.overflow = "hidden";
  //     } else {
  //       document.body.style.overflow = "visible";
  //     }

  //     if(this.sectionService.currentSection == "home") {
  //       if(this.lastDir == "down") {
  //         this.sectionService.goToSection("project-costs", $event);
  //       } else {
  //         this.sectionService.goToSection("home", $event);
  //       }
  //     }
  //     if(this.sectionService.currentSection == "project-costs") {
  //       if(this.lastDir == "down") {
  //         this.sectionService.goToSection("mitigates-risk", $event);
  //       } else {
  //         this.sectionService.goToSection("home", $event);
  //       }
  //     }
  //     if(this.sectionService.currentSection == "mitigates-risk") {
  //       if(this.lastDir == "down") {
  //         this.sectionService.goToSection("fosters-growth", $event);
  //       } else {
  //         this.sectionService.goToSection("project-costs", $event);
  //       }
  //     }
  //     if(this.sectionService.currentSection == "fosters-growth") {
  //       if(this.lastDir == "down") {
  //         this.sectionService.goToSection("support", $event);
  //       } else {
  //         this.sectionService.goToSection("mitigates-risk", $event);
  //       }
  //     }
  //     if(this.sectionService.currentSection == "support") {
  //       if(this.lastDir == "down") {
  //         this.sectionService.goToSection("approach", $event); 
  //       } else {
  //         this.sectionService.goToSection("fosters-growth", $event);
  //       }
  //     }
  //     if(this.sectionService.currentSection == "approach") {
  //       if(this.lastDir == "down") {
  //         // this.sectionService.goToSection("approach", $event); 
  //       } else {
  //         this.sectionService.goToSection("support", $event);
  //       }
  //     }

  //     setTimeout(() => {
  //       this.isScrolling = false;
  //     }, 1500);
  //   }
  // }


  @HostListener('window:resize', ['$event'])
  watchInnerHeight() {
    this.sectionService.watchInnerHeight();
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  startAnimation() {
    setTimeout(() => {
      this.anim.goToAndPlay(0);
    }, 100);

    this.anim.addEventListener("complete", (event) => {
      this.animCompleted = true;
    });
  }

  getViewHeight() {
    return window.innerHeight;
  }

  getViewWidth() {
    if(window.innerWidth > 500) {
      return 500;
    } else {
      return window.innerWidth;
    }
  }

  ngOnInit() {
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd)
    )
    .pipe(
      map(() => this.activatedRoute)
    )
    .pipe(
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      })
    )
    .pipe(
      filter((route) => route.outlet === 'primary')
    )
    .pipe(
      mergeMap((route) => route.data)
    )
    .subscribe((event) => {
      this.seo.updateTitle(event['title']);
      this.seo.updateOgUrl(event['ogUrl']);
      //Updating Description tag dynamically with title
      this.seo.updateDescription(event['title'] + event['description']);
      
      // if(this.router.url == "/" && !this.sectionService.touchDevice && !this.sectionService.shortDevice) {
      //   document.body.style.overflow = "hidden";
      // } else {
      //   document.body.style.overflow = "visible";
      // }
    });
    
    setTimeout(() => {
      this.startAnimation();
    }, 250);
  }
}
