import { Component, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { SectionService } from '../../services/section.service';

@Component({
  selector: 'app-approach',
  templateUrl: './approach.component.html',
  styleUrls: ['./approach.component.scss']
})
export class ApproachComponent implements AfterViewInit {

  viewing: boolean = false;
  viewed: boolean = false;

  mode;

  constructor(private sectionService: SectionService, private cd: ChangeDetectorRef) { }

  @HostListener('document:touchmove', ['$event'])
  @HostListener('window:scroll')
  onScroll() {
    // if(window.innerWidth <= 992) {
      let elem = document.querySelector('#in-viewport-approach');
      let isIn = this.isInViewport(elem);

      if(isIn) {
        this.sectionService.setSection('approach');
        if(!this.viewing) {
          this.viewing = true;
          setTimeout(() => {
            this.viewed = true;
          }, 1000);
        }
      }
    // }
  }

  isInViewport(elem) {
    let
      rect = elem.getBoundingClientRect(),
      windowHeight = (window.innerHeight || document.documentElement.clientHeight),
      percentVisible = 25;

    return !(
      Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
      Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
    )
  }
  
  getHeaderHeight() {
    return document.getElementsByTagName('header')[0].offsetHeight;
  }

  ngAfterViewInit() {
    this.sectionService.changes.subscribe((data) => {
      this.mode = this.sectionService.mode;

      if(this.sectionService.currentSection == 'approach') {
        this.viewing = true;
        setTimeout(() => {
          this.viewed = true;
        }, 1000);
      }
    });

    this.cd.detectChanges();
  }

}
