import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { SectionService } from '../../../services/section.service';

@Component({
  selector: 'app-mailto',
  templateUrl: './mailto.component.html',
  styleUrls: ['./mailto.component.scss']
})
export class MailtoComponent implements OnInit {

  @Input() options;

  mode;

  clients = [
    {
      name: 'Gmail',
      slug: 'gmail',
      img: './assets/contact/gmail.svg'
    },
    {
      name: 'Outlook',
      slug: 'outlook',
      img: './assets/contact/outlook.svg'
    },
    {
      name: 'Yahoo',
      slug: 'yahoo',
      img: './assets/contact/yahoo.svg'
    },
    {
      name: 'Default',
      slug: 'default',
      img: './assets/contact/default.svg'
    }
  ]

  showValue;

  copying: boolean = false;

  section = {
    title: "Let's talk",
    subtitle: "Shoot us a message to hear more about how starting with users <b>reduces project costs</b>."
  };

  email = "hello@rivetcx.com";

  subject = "";

  body = "";

  constructor(private sectionService: SectionService, private router: Router) { }

  openUrl(client, e) {
    switch(client) {
      case "gmail":
      window.open("https://mail.google.com/mail/?view=cm&fs=1&to=" + this.email + "&su= " + this.subject + "&cc=&bcc=&body=" + this.body, "_blank");
      break;

      case "outlook":
      window.open("https://outlook.office.com/owa/?path=/mail/action/compose&to=" + this.email + "&subject=" + this.subject + "&body=" + this.body, "_blank");
      break;

      case "yahoo":
      window.open("https://compose.mail.yahoo.com/?to=" + this.email + "&subject=" + this.subject + "&cc=&bcc=&body=" + this.body, "_blank");
      break;

      case "default":
      window.open("mailto:" + this.email + "?subject=" + this.subject + "&body=" + this.body, "_self");
      break;
    }
  }

  copyEmail(e) {
    this.copying = true;

    var copyText = document.getElementById("copy_email");
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();

    setTimeout(() => {
      this.copying = false;
    }, 2500);
  }

  ngOnInit() {
    this.sectionService.changes.subscribe((data) => {
      this.mode = this.sectionService.mode;

      this.clients.forEach((client) => {
        if(client.slug == "default") {
          if(this.mode == "dark") {
            client.img = "./assets/contact/default.svg";
          } else {
            client.img = "./assets/contact/default-dark.svg";
          }
        }
      });

      this.sectionService.sections.forEach((section) => {
        if(section.slug == this.sectionService.currentSection) {
          this.section.title = section.link;
          this.section.subtitle = "Shoot us a message to hear more about how starting with users <b>" + section.title + "</b>.";
        }
      });

      if(this.sectionService.currentSection == "services") {
        this.section.title = "Contact us";
        this.section.subtitle = "Shoot us a message to hear more about our <b>wide array of services</b>.";
      }
    });

    if(this.router.url == "/careers") {
      this.section.title = "Join our team";
      this.section.subtitle = "Interested in creating intuitive and compelling experiences? <b>Apply now</b>.";
    }

    if(this.options) {
      if(this.options["email"]) {
        this.email = this.options["email"];
      } else {
        this.email = "hello@rivetcx.com";
      }

      if(this.options["subject"]) {
        this.subject = this.options["subject"];
      } else {
        this.subject = "";
      }

      if(this.options["body"]) {
        this.body = this.options["body"];
      } else {
        this.body = "";
      }
    }
  }

}
