import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  changes:BehaviorSubject<any> = new BehaviorSubject(false);

  modal;

  options;

  constructor() { }

  setModal(value, options = null) {
    this.modal = value;

    this.options = options;

    this.changes.next(true);
  }

  close() {
    this.modal = null;
    
    this.changes.next(true);
  }
}
