import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { trigger, style, animate, query, stagger, transition, group } from '@angular/animations';

import { SectionService } from '../../services/section.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger(
      'fade',[
        transition(':enter', [
          style({opacity: '0'}),
          animate('250ms ease-out', style({opacity: '1'})),
        ]),

        transition(':leave', [
          style({opacity: '1'}),
          animate('250ms ease-out', style({opacity: '0'})),
        ])
      ]
    )
  ]
})
export class ModalComponent implements OnInit {
  @Input() modal: string;
  @Output() modalChange = new EventEmitter<string>();

  mode;

  options;

  constructor(private sectionService: SectionService, private modalService: ModalService, private renderer: Renderer2) { }

  close = function() {
    this.modal = null;
    this.renderer.removeClass(document.body, 'modal-open');
    this.modalService.close();
    this.modalChange.emit(null);
  }

  ngOnInit() {
    this.modalService.changes.subscribe((data) => {
      if(this.modalService.options) {
        this.options = this.modalService.options;
      } else {
        this.options = null;
      }
    });


    this.sectionService.changes.subscribe((data) => {
      if(this.mode != this.sectionService.mode) {
        this.mode = this.sectionService.mode;
      }
    });
  }

}
