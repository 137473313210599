import { Component, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { SectionService } from '../../services/section.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements AfterViewInit {

	services = [
    {
      title:'Digital User Strategy',
      img: './assets/services/digital-user-strategy.svg',
      anim: null,
      config: {
        'container': document.getElementById('lottie-approach-digital-strategy'),
        'slug':'digital-strategy',
        'name':'Digital Strategy',
        'renderer': 'svg',
        'autoplay': true,
        'loop': false,
        'path': './assets/animations/DigitalStrategy.json'
      }
    },
    {
      title:'Product Development Strategy',
      img: './assets/services/product-development-strategy.svg',
      anim: null,
      config: {
        'container': document.getElementById('lottie-approach-development-strategy'),
        'slug':'development-strategy',
        'name':'Development Strategy',
        'renderer': 'svg',
        'autoplay': true,
        'loop': false,
        'path': './assets/animations/ProductStrategy.json'
      }
    },
    {
      title:'User Research',
      anim: null,
      img: './assets/services/user-research.svg',
      config: {
        'container': document.getElementById('lottie-approach-user-research'),
        'slug':'user-research',
        'name':'User Research',
        'renderer': 'svg',
        'autoplay': true,
        'loop': false,
        'path': './assets/animations/UserExperience.json'
      }
    },
    {
      title:'Experience Design',
      img: './assets/services/experience-design.svg',
      anim: null,
      config: {
        'container': document.getElementById('lottie-approach-experience-design'),
        'slug':'experience-design',
        'name':'Experience Design',
        'renderer': 'svg',
        'autoplay': true,
        'loop': false,
        'path': './assets/animations/UserExperience.json'
      }
    },
    {
      title:'Design Systems',
      img: './assets/services/design-systems.svg',
      anim: null,
      config: {
        'container': document.getElementById('lottie-approach-design-systems'),
        'slug':'design-systems',
        'name':'Design Systems',
        'renderer': 'svg',
        'autoplay': true,
        'loop': false,
        'path': './assets/animations/DesignSystems.json'
      }
    },
    {
      title:'True-Fi Prototype Development',
      img: './assets/services/truefi-prototype-development.svg',
      anim: null,
      config: {
        'container': document.getElementById('lottie-approach-prototype-development'),
        'slug':'prototype-development',
        'name':'Prototype Development',
        'renderer': 'svg',
        'autoplay': true,
        'loop': false,
        'path': './assets/animations/TrueFiPrototypeDevelopment.json'
      }
    },
    {
      title:'Interface Design',
      img: './assets/services/interface-design.svg',
      anim: null,
      config: {
        'container': document.getElementById('lottie-approach-interface-design'),
        'slug':'interface-design',
        'name':'interface Design',
        'renderer': 'svg',
        'autoplay': true,
        'loop': false,
        'path': './assets/animations/InterfaceDesign.json'
      }
    },
    {
      title:'Usability Testing',
      img: './assets/services/usability-testing.svg',
      anim: null,
      config: {
        'container': document.getElementById('lottie-approach-usability-testing'),
        'slug':'usability-testing',
        'name':'Usability Testing',
        'renderer': 'svg',
        'autoplay': true,
        'loop': false,
        'path': './assets/animations/DesignTesting.json'
      }
    }
  ]

  viewing: boolean = false;
  viewed: boolean = false;

  interval: any;

  mode;

  constructor(private sectionService: SectionService, private modalService: ModalService, private cd: ChangeDetectorRef) { }

  @HostListener('document:touchmove', ['$event'])
  @HostListener('window:scroll')
  onScroll() {
    let elem = document.querySelector('#in-viewport-services');
    let isIn = this.isInViewport(elem);

    if(isIn) {
      this.sectionService.setSection('services');
      if(!this.viewing) {
        this.viewing = true;
        setTimeout(() => {
          this.viewed = true;
        }, 1500);
      }
    }
  }

  isInViewport(elem) {
    let
      rect = elem.getBoundingClientRect(),
      windowHeight = (window.innerHeight || document.documentElement.clientHeight),
      percentVisible = 25;

    return !(
      Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
      Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
    )
  }

  getHeaderHeight() {
    return document.getElementsByTagName('header')[0].offsetHeight;
  }

  setModal(value, e){
    e.preventDefault();
    this.modalService.setModal(value);
  }

  handleAnimation(service, anim: any) {
    service.anim = anim;
  }

  startAnimation(service) {
    setTimeout(() => {
      service.anim.goToAndPlay(0);
    }, 100);
  }

  openUrl(_url, _opt) {
    window.open(_url, _opt);
  }

  ngAfterViewInit() {
    this.sectionService.changes.subscribe((data) => {
      this.mode = this.sectionService.mode;

      if(this.sectionService.currentSection == 'services') {
        this.viewing = true;
          setTimeout(() => {
            this.viewed = true;
          }, 1500);
      }
    });

    this.cd.detectChanges();
  }
}
