import { Component, OnInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { trigger, style, animate, query, stagger, transition } from '@angular/animations';

import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-careers',
  animations: [
    trigger(
      'fadeIn',[
        transition(':enter', [
          query('*', [
            style({opacity:0}),
            stagger(50, [ animate('500ms', style({opacity:1})) ])
          ])
        ])
      ]
    )
  ],
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent {
  listings = [
    {
      "title":"Senior UI Designer",
      "overview":"Rivet CX is looking for talented design leader with ten or more years of experience to join us in leading our design efforts creating intuitive and compelling user experiences.",
      "description":"Our UI Designers make the most significant impact thinking creatively solving both system-wide and single-instance challenges that translate complexity into simple interface solutions. The ideal candidate should possess excellent design skills and be able to lead related project activities. These activities include translating high-level requirements into project work scopes, resource planning, and presenting solutions with stakeholders throughout project phases. The ideal candidate possesses strong communication skills, especially when collaborating with other employees and clients on a range of topics and contexts.",
      "specifics":[
        "Full Time",
        "On-Site",
        "Occasional Travel",
        "Salary dependent on experience and skill",
        "Must be eligible to work in the United States (unable to sponsor a work visa)"
      ],
      "responsibilities":[
        "Establish and lead the proliferation of best practices for UI design approaches and methods",
        "Be responsible for all client-facing design deliverables",
        "Be responsible for leading design solutions for multiple platforms (e.g., iOS, Android, web, etc.)",
        "Translate client business requirements, user needs, and technical requirements to define and implement concepts",
        "Create wireframes, storyboards, user flows, process flows and site maps to effectively communicate interaction and design concepts",
        "Design new visuals and/or refine existing to create a high designed visual user experience",
        "Present the rationale for your thinking that addresses provided inputs and requirements",
        "Support sales efforts and activities including leading discussions and group activities that engage clients in requirements and discovery of user needs",
        "Continually keep yourself and the team educated with the latest changes in the industry’s standards"
      ],
      "experience":[
        "Ten or more years of experience",
        "A strong portfolio representing the creation of various design solutions and deliverables: wireframes, low and high fidelity designs, prototypes, UI solutions, visual style guides/design systems for multiple platforms (e.g., iOS, Android, web, etc.)",
        "Experience executing at all design phases; translating requirements and high-level direction into multiple concepts, concept refinement, and creating final deliverables",
        "Strong record of leading design-centric discussions during pre-sales, proposal, kick-off, design, and production project phases",
        "Comfort leading diverse groups of stakeholders and internal resources through design phases and activities",
        "A bachelor’s degree or higher in Graphic Design, New Media, HCI, Interaction Design, or an equivalent degree",
        "Experience with interactive design applications (e.g., Sketch, Zeplin, and Adobe Illustrator, etc.)"
      ],
      "skills":[
        "Strong typography, layout, and information design skills especially related to User Interface design",
        "Strong knowledge of human-centered design approaches and activities",
        "A demonstrable ability to utilize responsive design and grid principles",
        "Ability to visualize abstract concepts and models (e.g., interaction, domain, workflow, etc.)",
        "Strong visual skills creating wireframes, UI design, screen layout, full design systems",
        "A demonstrable ability to design pleasing color systems that meet various needs (e.g., UI usability, client brand, contrast, compliance, etc.)",
        "A working understanding of digital production (e.g., HTML, JavaScript, and CSS, etc.)",
        "Experienced in leading design for multiple platforms (e.g., iOS, Android, web, etc.)",
        "Experience creating UI rapid prototypes",
        "Ability to design and illustrate graphic symbols and icons",
        "Identify appropriate design activities and scope levels of effort"
      ],
      "softSkills":[
        "Proactive, demonstrable ability to take the initiative in a given situation",
        "Effective time management and proven record of meeting deadlines",
        "Tenacious problem-solver",
        "Demonstrably strong attention to detail",
        "Comfortable establishing and upholding design strategy through all project phases",
        "Able to reconcile disparate inputs, form a coherent direction, and to continually iterate to arrive at a final solution",
        "Willingness to learn and grow as a designer",
        "Strong communication and teamwork skills",
        "A demonstrable ability to represent design value when communicating with sales prospects and current clients alike"
      ],
      "bonusSkills":[
        "Working understanding of various design testing methodologies and activities",
        "Working experience in a Sketch and Zeplin workflow",
        "An understanding of Agile, Lean or other software development methodologies",
        "Working knowledge of Google Docs"
      ],
      "notes":"* Please supply a link to an online portfolio in your cover letter when applying"
    },{
      "title":"Junior UI Designer",
      "overview":"Rivet CX is looking for a talented Junior UI Designer with two or more years of experience to join us in creating intuitive and compelling user experiences.",
      "description":"Our UI Designers make the most significant impact thinking creatively solving both system-wide and single-instance challenges that translate complexity into simple interface solutions. The ideal candidate should possess excellent design skills and be able to translate high-level requirements into flows and interactions, transforming them into intuitive, and functional solutions. The ideal candidate possesses strong communication skills, especially when collaborating with other employees and clients on a range of topics and contexts.",
      "specifics":[
        "Full Time",
        "On-Site",
        "Occasional Travel",
        "Salary dependent on experience and skill",
        "Must be eligible to work in the United States (unable to sponsor a work visa)"
      ],
      "responsibilities":[
        "Translate client business requirements, user needs, and technical requirements to define and implement concepts",
        "Create wireframes, storyboards, user flows, process flows and site maps to effectively communicate interaction and design ideas",
        "Design new visuals and/or refine existing to create highly a designed visual experience",
        "Present the rationale for your thinking that addresses provided inputs and requirements",
        "Establish and promote design guidelines, best practices, and standards",
        "Continually keep yourself educated with the latest changes in industry’s standards",
        "Be familiar with and able to create platform-specific solutions (e.g., iOS, Android, web, etc.)."
      ],
      "experience":[
        "A bachelor’s degree or higher in Graphic Design, New Media, HCI, Interaction Design, or an equivalent degree",
        "A strong portfolio representing two or more years of experience in the creation of various UI design deliverables: wireframes, lo-fi and hi-fi layouts, prototypes, visual style guides/design systems",
        "Experience executing at all design phases; translating requirements and high-level direction into multiple concepts, concept refinement, and creating final solutions",
        "Experience with design and interactive applications, especially Sketch, Zeplin, and Adobe Illustrator"
      ],
      "skills":[
        "Strong typography, layout, and information design skills",
        "Working knowledge of human-centered design approaches",
        "Utilize responsive design and grid principles",
        "Ability to visualize abstract concepts and models (e.g., interaction, domain, workflow, etc.)",
        "Ability to create strong visual representations of systems in multiple phases: wireframes, UI design, screen layout, full design systems",
        "Ability to design and illustrate graphic symbols and icons",
        "Ability to design pleasing color systems that meet various needs (e.g., client brand, contrast, compliance, etc.)"
      ],
      "softSkills":[
        "Proactive, ability to take an initiative",
        "Effective time management and ability to meet deadlines",
        "Tenacious problem-solver",
        "Strong attention to detail",
        "Open to receiving direction, to continually iterate, and willingness to learn and grow as a designer",
        "Ability to learn new tools and processes quickly",
        "Strong communication and teamwork skills"
      ],
      "bonusSkills":[
        "Identify appropriate design activities and scope levels of effort",
        "Working knowledge of User Experience strategies and methodologies",
        "A working understanding of HTML, JavaScript, and CSS",
        "Working knowledge of designing for iOS, Android and web platforms",
        "Experience creating rapid prototypes",
        "An understanding of Agile, Lean or other software development methodologies",
        "An understanding of various design testing methodologies and activities",
        "A working knowledge of Google Docs esp designing presentations in Slides"
      ],
      "notes":"* Please supply a link to an online portfolio in your cover letter when applying"
    }
  ]

  modal;

  constructor(private modalService: ModalService, private renderer: Renderer2, private cd: ChangeDetectorRef) { }

  getHeaderHeight() {
    return document.getElementsByTagName('header')[0].getElementsByClassName('header')[0].clientHeight;
  }

  setModal(value) {
    if(value && value != ''){
      this.renderer.addClass(document.body, 'modal-open');
    }
    else {
      this.renderer.removeClass(document.body, 'modal-open');
    }
    this.modal = value;
  }

  ngOnInit() {
    this.modalService.changes.subscribe((data) => {
      this.setModal(this.modalService.modal);
    });

    setTimeout(() => {
      window.scrollTo(0,0);
    }, 250);
  }
}
