import { Component, Input, HostListener, AfterViewInit, ChangeDetectorRef, Renderer2 } from '@angular/core';

import { SectionService } from '../../services/section.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements AfterViewInit {
  
  @Input() section: {
    title:any,
    slug:any,
    theme: any,
    index: any,
    description:any,
    link:any,
    graph:{
      title:any,
      footnote:{
        title:any,
        url:any
      },
      config: {
        container:any,
        renderer:string,
        autoplay: boolean,
        loop: boolean,
        path: any
      }
    }
  };

  @Input() sections;
  @Input() current;

  anim: any = null;

  viewing: boolean = false;
  viewed: boolean = false;
  resetting: boolean = false;

  mode;

  modal;

  constructor(private sectionService: SectionService, private modalService: ModalService, private cd: ChangeDetectorRef, private renderer: Renderer2) { }

  @HostListener('document:touchmove', ['$event'])
  @HostListener('window:scroll')
  onScroll() {
    let elem = document.querySelector('#' + this.section.slug);
    let isIn = this.isInViewport(elem);

    if(isIn) {
      this.sectionService.setSection(this.section.slug);
      if(!this.viewing) {
        this.viewing = true;
        setTimeout(() => {
          this.viewed = true;
        }, 1000);
      }
    }
  }

  isInViewport(elem) {
    let
      rect = elem.getBoundingClientRect(),
      windowHeight = (window.innerHeight || document.documentElement.clientHeight),
      percentVisible = 25;

    return !(
      Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
      Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
    )
  }

  openUrl(_url, _opt) {
    window.open(_url, _opt);
  }

  getWindowHeight() {
    return window.innerHeight;
  }

  getHeaderHeight() {
    return document.getElementsByTagName('header')[0].offsetHeight;
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  startAnimation() {
    setTimeout(() => {
      this.anim.goToAndPlay(0);
    }, 250);
  }

  setModal(value, e){
    e.preventDefault();
    this.modalService.setModal(value);
  }

  ngAfterViewInit() {
    this.sectionService.changes.subscribe((data) => {
      if(this.mode != this.sectionService.mode) {
        this.mode = this.sectionService.mode;
        this.resetting = true;

        if(this.mode == "dark") {
          if(this.section.slug == "project-costs") {
            this.section.graph.config.path = './assets/animations/SectionOne_ProjectCosts_Graph_Dark.json';
          }
          if(this.section.slug == "mitigates-risk") {
            this.section.graph.config.path = './assets/animations/SectionTwo_MitigateRisk_Graph_Dark.json';
          }
          if(this.section.slug == "fosters-growth") {
            this.section.graph.config.path = './assets/animations/SectionThree_FostersGrowth_Graph_Dark.json';
          }
          if(this.section.slug == "support") {
            this.section.graph.config.path = './assets/animations/SectionFour_Support_Graph_Dark.json';
          }
        }
        else {
          if(this.section.slug == "project-costs") {
            this.section.graph.config.path = './assets/animations/SectionOne_ProjectCosts_Graph.json';
          }
          if(this.section.slug == "mitigates-risk") {
            this.section.graph.config.path = './assets/animations/SectionTwo_MitigateRisk_Graph.json';
          }
          if(this.section.slug == "fosters-growth") {
            this.section.graph.config.path = './assets/animations/SectionThree_FostersGrowth_Graph.json';
          }
          if(this.section.slug == "support") {
            this.section.graph.config.path = './assets/animations/SectionFour_Support_Graph.json';
          }
        }

        setTimeout(() => {
          this.resetting = false;
          this.startAnimation();
        }, 750);
      }

      if(this.sectionService.currentSection == this.section.slug) {
        if(!this.viewing) {
          this.startAnimation();
        }
        this.viewing = true;

        setTimeout(() => {
          this.viewed = true;
        }, 1000);
      }
    });

    this.modalService.changes.subscribe((data) => {
      this.modal = this.modalService.modal;
    });

    this.cd.detectChanges();
  }
}
