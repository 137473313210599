import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SectionService {
  
  changes:BehaviorSubject<any> = new BehaviorSubject(false);

  sections = [
    {
      title:'reduces project costs',
      slug:'project-costs',
      theme:'dark',
      index: 1,
      description:'Fixing an issue in early requirements or design phases versus fixing it in production, significantly reduces total project costs. By skipping user strategy and design research, projects are exposed to costly rework, missed deadlines and bad first impressions with users. The adage still applies: “measure twice, cut once.”',
      link: 'Let\'s talk',
      graph: {
        title:'Average Cost per Life Cycle Phase',
        footnote:{
          title:'Error Cost Escalation Through the Project Life Cycle',
          url:'https://ntrs.nasa.gov/archive/nasa/casi.ntrs.nasa.gov/20100036670.pdf'
        },
        config: {
          container: document.getElementById('lottie-project-costs'),
          name:'Average Cost per Life Cycle',
          renderer: 'svg',
          autoplay: false,
          loop: false,
          path: './assets/animations/SectionOne_ProjectCosts_Graph.json'
        }
      }
    },
    {
      title:'mitigates risk',
      slug:'mitigates-risk',
      theme:'light',
      index: 2,
      description:'Testing early and often provides unique insights into market demand and user preferences. Testing simple or full fidelity prototypes can bring all stakeholders to a common understanding of the user, usually by exposing unsubstantiated opinions and biases. If a picture is worth a thousand words, we believe a prototype is worth a thousand meetings. ',
      link: 'Drop us a line',
      graph: { 
        title:'Percentage of Rework Time',
        footnote:{
          title:'IEEE\'s report \'Why software fails\'',
          url:'https://doi.org/10.1109/MSPEC.2005.1502528'
        },
        config: {
          container: document.getElementById('lottie-mitigates-risk'),
          name:'Percentage of Rework Time',
          renderer: 'svg',
          autoplay: false,
          loop: false,
          path: './assets/animations/SectionTwo_MitigateRisk_Graph.json'
        }
      }
    },
    {
      title:'fosters growth',
      slug:'fosters-growth',
      theme:'dark',
      index: 3,
      description:'By focusing on user needs and preferences, Rivet CX digital solutions can deliver greater loyalty and increase word of mouth. Companies with top-quartile McKinsey Design Index (MDI) scores enjoy roughly double revenue growth.',
      link: 'Contact us',
      graph: {
        title:'Annual Revenue Growth (normalized)',
        footnote:{
          title:'McKinsey\'s Business Value of Design',
          url:'https://www.mckinsey.com/business-functions/mckinsey-design/our-insights/the-business-value-of-design'
        },
        config: {
          container: document.getElementById('lottie-fosters-growth'),
          name:'Annual Revenue Growth (normalized)',
          renderer: 'svg',
          autoplay: false,
          loop: false,
          path: './assets/animations/SectionThree_FostersGrowth_Graph.json'
        }
      }
    },
    {
      title:'reduces support costs',
      slug:'support',
      theme:'light',
      index: 4,
      description:'The ability for users to self-serve and complete more tasks online or in an app without a support call lowers overhead. We believe a small support group is a telling sign of a well-designed, mature, user-focused product. ',
      link: 'Let\'s chat',
      graph: {
        title:'Support costs decrease as user experience improves',
        config: {
          container: document.getElementById('lottie-support'),
          name:'Support costs decrease as user experience improves',
          renderer: 'svg',
          autoplay: false,
          loop: false,
          path: './assets/animations/SectionFour_Support_Graph.json'
        }
      }
    }
  ]

  navigation = {
    full: [
      {'name':'Home', 'slug':'home'},
      {'name':'Project Costs', 'slug':'project-costs'},
      {'name':'Mitigates Risk', 'slug':'mitigates-risk'},
      {'name':'Fosters Growth', 'slug':'fosters-growth'},
      {'name':'Support Costs', 'slug':'support'},
      {'name':'Approach', 'slug':'approach'},
      {'name':'People', 'slug':'people'},
      {'name':'Services', 'slug':'services'},
      {'name':'Clients', 'slug':'clients'}
    ],
    condensed: [
      {'name':'What We Value', 'slug':'home', index: 0},
      {'name':'Who We Are', 'slug':'people', index: 7},
      {'name':'What We\'re Good At', 'slug':'services', index: 8},
      {'name':'Who We Work With', 'slug':'clients', index: 9}
    ]
  }

  currentSection = 'home';

  mode;
  sessionMode = null;
  
  touchDevice;
  shortDevice;

  constructor() {
    this.watchForTouch();
    this.watchInnerHeight();
    this.watchMode();
  }

  watchForTouch() {
    try {
      document.createEvent("TouchEvent");
      this.touchDevice = true;
      document.body.classList.add('proximity');
    } catch(e) {
      this.touchDevice = false;
    }
  }

  watchInnerHeight() {
    if(window.innerHeight <= 644) {
      this.shortDevice = true;
      document.body.classList.add('proximity');
    } else {
      this.shortDevice = false;
    }
  }

  watchMode() {
    setTimeout(() => {
      let _mode = "light";
      if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && !this.sessionMode) {
        _mode = "dark";
      } 
      if(this.mode != _mode) {
        this.mode = this.sessionMode || _mode;
        this.changes.next(true);
      }
      if(!this.sessionMode) {
        this.watchMode();
      }
    }, 1000);
  }

  getHeaderHeight() {
    if(document.getElementsByTagName('header')[0]) {
      return document.getElementsByTagName('header')[0].offsetHeight;
    }
  }

  setSessionMode(mode) {
    this.sessionMode = mode;
    this.mode = mode;

    document.body.classList.remove('dark');
    document.body.classList.remove('light');
    document.body.classList.add(mode);

    document.getElementsByTagName('header')[0].classList.remove('dark');
    document.getElementsByTagName('header')[0].classList.remove('light');
    document.getElementsByTagName('header')[0].classList.add(mode);

    this.changes.next(true);
  }

  goToSection(slug, event) {
    let el = document.getElementById(slug);
    let headerOffset = this.getHeaderHeight();
    let bodyRect = document.body.getBoundingClientRect().top;
    let elementRect = el.getBoundingClientRect().top;
    let elementPosition = elementRect - bodyRect;
    let offsetPosition = elementPosition; 
    if(window.innerWidth <= 992) {
      offsetPosition = elementPosition - headerOffset;
    }

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  setSection(section) {
    this.currentSection = section;
    this.changes.next(true);
  }
}
