import { Component, HostListener, OnInit, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { trigger, style, animate, query, stagger, transition } from '@angular/animations';

import { SectionService } from '../../services/section.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-home',
  animations: [
    trigger(
      'fade',[
        transition(':enter', [
          query('h1', [
            style({opacity:0}),
            stagger(750, [
              animate('1000ms', style({opacity:1}))
            ])
          ])
        ]),
        transition(':leave', [
          query('h1', [
            style({opacity:1}),
            stagger(750, [
              animate('1000ms', style({opacity:0}))
            ])
          ])
        ])
      ]
    ),
    trigger(
      'slideFromLeft',[
        transition(':enter', [
          query('ul', [
            style({transform: 'translateX(-120%)'}),
            animate('500ms', style({transform: 'translateX(0)'}))
          ])
        ]),
        transition(':leave', [
          query('ul', [
            style({transform: 'translateX(0)'}),
            animate('500ms', style({transform: 'translateX(-120%)'}))
          ])
        ])
      ]
    ),
    trigger(
      'slideFromBottom',[
        transition(':enter', [
          query('*', [
            style({transform: 'translateY(120%)'}),
            animate('250ms', style({transform: 'translateY(0)'}))
          ])
        ]),
        transition(':leave', [
          query('*', [
            style({transform: 'translateY(0)'}),
            animate('250ms', style({transform: 'translateY(120%)'}))
          ])
        ])
      ]
    )
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  scrolled: boolean = false;
  viewing: boolean = false;
  viewed: boolean = false;
  contentLoaded: boolean = false;
  animationLoaded: boolean = false;
  resettingAnimation: boolean = false;
  
  anim: any;

  graph = {
    config: {
      container: document.getElementById('lottie-mouse'),
      renderer: 'svg',
      autoplay: true,
      loop: true,
      path: './assets/animations/MouseScroll.json'
    }
  }

  // fullpage: any;

  sections;
  mode;
  navigation;

  modal;

  touch;

  constructor(private sectionService: SectionService, private cd: ChangeDetectorRef, private modalService: ModalService, private renderer: Renderer2) {
    // this.fullpage = this.sectionService.fullpage;
    this.sections = this.sectionService.sections;
  }

  @HostListener('document:touchmove', ['$event'])
  @HostListener('window:scroll')
  onScroll() {
    if(!this.scrolled) {
      this.scrolled = true;
    }

    let elem = document.querySelector('#home');
    let isIn = this.isInViewport(elem);

    if(isIn) {
      this.sectionService.setSection('home');
    }
  }

  isInViewport(elem) {
    let
      rect = elem.getBoundingClientRect(),
      windowHeight = (window.innerHeight || document.documentElement.clientHeight),
      percentVisible = 25;

    return !(
      Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
      Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
    )
  }

  goToSection(slug, event) {
    this.sectionService.goToSection(slug, event);
  }

  getHeaderHeight() {
    return document.getElementsByTagName('header')[0].offsetHeight;
  }

  getIndicatorsHeight() {
    if(document.querySelector('.indicators')) {
      return (<HTMLElement>document.querySelector('.indicators')).offsetHeight;
    }
  }

  handleAnimation(anim: any) {
    this.anim = anim;

    setTimeout(() => {
      this.animationLoaded = true;
    }, 1500);
  }

  startAnimation() {
    setTimeout(() => {
      this.anim.goToAndPlay(0);
    }, 250);
  }

  scrollTo(element) {
    setTimeout(() => {
      let el = document.getElementById(element);
      // let headerOffset = this.getHeaderHeight();
      let headerOffset = 0;
      let bodyRect = document.body.getBoundingClientRect().top;
      let elementRect = el.getBoundingClientRect().top;
      let elementPosition = elementRect - bodyRect;
      let offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }, 250);
  }

  getWindowHeight() {
    return window.innerHeight;
  }

  isDesktop() {
    if(window.innerWidth > 992) {
      return true;
    }
    return false;
  }

  setModal(value) {
    if(value && value != ''){
      this.renderer.addClass(document.body, 'modal-open');
    }
    else {
      this.renderer.removeClass(document.body, 'modal-open');
    }
    this.modal = value;
  }

  ngOnInit() {
    this.modalService.changes.subscribe((data) => {
      this.setModal(this.modalService.modal);
    });

    this.sectionService.currentSection = 'home';
    
    this.sectionService.changes.subscribe((data) => {
      this.modalService.close();

      if(!this.navigation) {
        this.navigation = this.sectionService.navigation.full;
      }

      this.resettingAnimation = true;

      this.mode = this.sectionService.mode;
      this.touch = this.sectionService.touchDevice;

      if(this.mode == "dark") {
        this.graph.config.path = './assets/animations/MouseScrollDark.json';
      }

      if(this.mode == "light") {
        this.graph.config.path = './assets/animations/MouseScroll.json';
      }

      setTimeout(() => {
        this.resettingAnimation = false;
      }, 750);

      if(this.sectionService.currentSection == 'home') {
        setTimeout(() => {
          this.viewing = true;
        }, 1000);
      }
    });

    setTimeout(() => {
      this.contentLoaded = true;
      this.cd.detectChanges();
    }, 750);

    setTimeout(() => {
      this.viewing = true;

      setTimeout(() => {
        this.viewed = true;
      }, 750);
    }, 1000);
  }

}
