import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { GtagModule } from 'angular-gtag';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { LottieAnimationViewModule } from 'ng-lottie';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { HomeComponent } from './pages/home/home.component';
import { SectionComponent } from './components/section/section.component';
import { ApproachComponent } from './components/approach/approach.component';
import { PeopleComponent } from './components/people/people.component';
import { ServicesComponent } from './components/services/services.component';
import { ClientsComponent } from './components/clients/clients.component';

import { CareersComponent } from './pages/careers/careers.component';
import { ListingComponent } from './components/listing/listing.component';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { ToggleComponent } from './components/toggle/toggle.component';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { IndicatorsComponent } from './components/indicators/indicators.component';
import { MailtoComponent } from './components/modals/mailto/mailto.component';
import { ModalComponent } from './components/modal/modal.component';

@NgModule({
  declarations: [
    AppComponent,

    HomeComponent,
    SectionComponent,
    ApproachComponent,
    PeopleComponent,
    ServicesComponent,
    ClientsComponent,

    CareersComponent,
    ListingComponent,

    HeaderComponent,
    FooterComponent,

    ToggleComponent,
    HamburgerComponent,
    IndicatorsComponent,
    MailtoComponent,
    ModalComponent
  ],
  imports: [
    AppRoutingModule,
    RouterModule.forRoot([]),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    GtagModule.forRoot({ trackingId: 'UA-71088287-1', trackPageviews: true }),
    Nl2BrPipeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    LottieAnimationViewModule.forRoot(),
    AngularSvgIconModule
  ],
  providers: [MailtoComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
