import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, query, stagger, transition } from '@angular/animations';

import { SectionService } from '../../services/section.service';

@Component({
  selector: 'app-indicators',
  animations: [
    trigger(
      'slideFromLeft',[
        transition(':enter', [
          query('ul', [
            style({transform: 'translateX(-120%)'}),
            animate('500ms', style({transform: 'translateX(0)'}))
          ])
        ]),
        transition(':leave', [
          query('ul', [
            style({transform: 'translateX(0)'}),
            animate('500ms', style({transform: 'translateX(-120%)'}))
          ])
        ])
      ]
    )
  ],
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss']
})
export class IndicatorsComponent implements OnInit {

  mode;

  navigation;

  contentLoaded: boolean = false;

  constructor(private sectionService: SectionService) {
    
  }

  getHeaderHeight() {
    return document.getElementsByTagName('header')[0].offsetHeight;
  }

  getWindowHeight() {
    return window.innerHeight;
  }

  getIndicatorsHeight() {
    return document.getElementsByClassName('indicators')[0].clientHeight;
  }

  goToSection(slug, event) {
    this.sectionService.goToSection(slug, event);
  }

  ngOnInit() {
    this.sectionService.changes.subscribe((data) => {
      this.mode = this.sectionService.mode;

      if(!this.navigation) {
        this.navigation = this.sectionService.navigation.full;
      }
    });

    setTimeout(() => {
      this.contentLoaded = true;
    }, 750);
  }

}
