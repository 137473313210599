import { Component, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { trigger, style, animate, query, stagger, transition, state } from '@angular/animations';

import { SectionService } from '../../services/section.service';

export const fade = [
  trigger('fade', [
    state('in', style({ 'opacity': '1' })),
    state('out', style({ 'opacity': '0' })),
    transition('* <=> *', [
      animate(250)
    ])
  ])
];

export const slide = [
  trigger(
    'slide',[
      state('in', style({ 'transform': 'translateX(-50%)' })),
      state('out', style({ 'transform': 'translateX(0)' })),
      transition('* <=> *', [
        animate(250)
      ])
    ]
  )
]

@Component({
  selector: 'app-people',
  animations: [fade, slide],
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements AfterViewInit {

  people = [
    {
      name: 'Damir Saracevic',
      slug: 'damir',
      title: 'President & Founder',
      email: 'damir@rivetcx.com',
      img: './assets/people/damir.png',
      hover: './assets/people/damir-hover.png'
    },
    {
      name: 'Janine Yagielski',
      slug: 'janine',
      title: 'Director of User Experience',
      email: 'janine@rivetcx.com',
      img: './assets/people/janine.png',
      hover: './assets/people/janine-hover.png'
    },
    {
      name: 'Keenan Barry',
      slug: 'keenan',
      title: 'Director of User Experience',
      email: 'keenan@rivetcx.com',
      img: './assets/people/keenan.png',
      hover: './assets/people/keenan-hover.png'
    },
    {
      name: 'Bradley Foust',
      slug: 'brad',
      title: 'Director of Operations',
      email: 'bradley@rivetcx.com',
      img: './assets/people/brad.png',
      hover: './assets/people/brad-hover.png'
    },
    {
      name: 'Joshua Jinks',
      slug: 'josh',
      title: 'Director of Operations',
      email: 'josh@rivetcx.com',
      img: './assets/people/josh.png',
      hover: './assets/people/josh-hover.png'
    },
    // {
    //   name: 'Sue Sager',
    //   slug: 'sue',
    //   title: 'Director of User Experience',
    //   email: 'sue@rivetcx.com',
    //   img: './assets/people/sue.png',
    //   hover: './assets/people/sue-hover.png'
    // },
    {
      name: 'Rui Da Costa',
      slug: 'rui',
      title: 'Director of Interaction Design',
      email: 'rui@rivetcx.com',
      img: './assets/people/rui.png',
      hover: './assets/people/rui-hover.png'
    },
    {
      name: 'Athanasios Babasidis',
      slug: 'athanasios',
      title: 'Director of Interaction Design',
      email: 'athanasios@rivetcx.com',
      img: './assets/people/athanasios.png',
      hover: './assets/people/athanasios-hover.png'
    },
    {
      name: 'Logan Bicknell',
      slug: 'logan',
      title: 'Director of Visual Design',
      email: 'logan@rivetcx.com',
      img: './assets/people/logan.png',
      hover: './assets/people/logan-hover.png'
    },
    {
      name: 'Dan Keating',
      slug: 'dan',
      title: 'Director of Business Development',
      email: 'dan@rivetcx.com',
      img: './assets/people/dan.png',
      hover: './assets/people/dan-hover.png'
    },
    // {
    //   name: 'Joe Gray',
    //   slug: 'joe',
    //   title: 'User Experience Apprentice',
    //   email: 'joe@rivetcx.com',
    //   img: './assets/people/joe.png',
    //   hover: './assets/people/joe-hover.png'
    // }
  ]

  certifications = [
    {
      src: './assets/certifications/forrester-logo-light.svg',
      alt: 'Forrester'
    },
    {
      src: './assets/certifications/nng-logo-light.svg',
      alt: 'NN/g'
    }
  ]

  viewing: boolean = false;
  viewed: boolean = false;

  mode;

  constructor(private sectionService: SectionService, private cd: ChangeDetectorRef) { }

  @HostListener('document:touchmove', ['$event'])
  @HostListener('window:scroll')
  onScroll() {
    let elem = document.querySelector('#in-viewport-people');
    let isIn = this.isInViewport(elem);

    if(isIn) {
      this.sectionService.setSection('people');
      if(!this.viewing) {
        this.viewing = true;
        setTimeout(() => {
          this.viewed = true;
        }, 1500);
      }
    }
  }

  isInViewport(elem) {
    let
      rect = elem.getBoundingClientRect(),
      windowHeight = (window.innerHeight || document.documentElement.clientHeight),
      percentVisible = 25;

    return !(
      Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
      Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
    )
  }

  isDesktop() {
    if(window.innerWidth > 992) {
      return true;
    }
    return false;
  }

  getHeaderHeight() {
    return document.getElementsByTagName('header')[0].offsetHeight;
  }


  handleAnimation(service, anim: any) {
    service.anim = anim;
  }

  startAnimation(service) {
    setTimeout(() => {
      service.anim.goToAndPlay(0);
    }, 100);
  }

  mailTo(email, e) {
    e.preventDefault();
    window.open('mailto:' + email, '_self');
  }

  openUrl(_url, _opt) {
    window.open(_url, _opt);
  }

  ngAfterViewInit() {
    this.sectionService.changes.subscribe((data) => {
      this.mode = this.sectionService.mode; 

      if(this.mode) {
        this.certifications.forEach((cert) => {
          let src = cert.src.replace("light", "");
          src = src.replace("dark", "");
          src = src.replace(".svg", this.mode + ".svg");

          cert.src = src;
        });
      }

      setTimeout(() => {
        if(this.sectionService.currentSection == 'people') {
          this.viewing = true;
          setTimeout(() => {
            this.viewed = true;
          }, 1500);
        }
      }, 250);
    });

    this.cd.detectChanges();
  }

}
