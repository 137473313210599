import { Component, OnInit, Input } from '@angular/core';

import { SectionService } from '../../services/section.service';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent implements OnInit {

  @Input() state = 'closed';

  mode;

  constructor(private sectionService: SectionService) { }

  ngOnInit() {
    this.sectionService.changes.subscribe((data) => {
      this.mode = this.sectionService.mode;
    });
  }

}
