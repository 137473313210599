import { Component, OnInit } from '@angular/core';

import { SectionService } from '../../services/section.service';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  elToggle;
  elApp;
  machine;

  constructor(private sectionService: SectionService) { }

  clickToggle() {
    const currentState = this.elApp.dataset.state;
    const nextState =  this.machine.states[currentState].on.TOGGLE;
    this.toggleState(nextState);
  }

  toggleState(state) {
    this.sectionService.setSessionMode(state);
  }

  ngOnInit() {
    this.elToggle = document.querySelector('.toggle-wrapper');
    this.elApp = document.querySelector('.toggle-container');
    this.machine = {
      initial: this.elApp.dataset.state,
      states: {
        light: {
          on: {
            TOGGLE: 'dark'
          }
        },
        dark: {
          on: {
            TOGGLE: 'light'
          }
        },
      }
    };

    this.sectionService.changes.subscribe((data) => {
      this.elApp.dataset.state = this.sectionService.mode;
    });
  }

}
