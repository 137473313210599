import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SectionService } from '../../services/section.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  mode;

  navigation;

    currentRoute: {
    'url': any
  }

  constructor(private router: Router, private sectionService: SectionService) {
    this.currentRoute = router;
  }

  goToSection(slug, event) {
    if(this.currentRoute.url != '/') {
      this.router.navigate(['/']);
      setTimeout(() => {
        this.sectionService.goToSection(slug, event);
      }, 1500);
    } else {
      this.sectionService.goToSection(slug, event);
    }
  }

  ngOnInit() {
    this.sectionService.changes.subscribe((data) => {
      if(!this.navigation) {
        this.navigation = this.sectionService.navigation.condensed;
      }

      this.mode = this.sectionService.mode;
    });
  }

}
